import Link from "next/link";
import { Collapsible } from "@shopify/polaris";

const styles = {
  collapseAnimation: {
    duration: "150ms",
    timingFunction: "ease",
  },
};

const HamburgerMenu = ({ open, selected, handleSelected }) => {
  return (
    <>
      <Collapsible open={open} transition={styles.collapseAnimation}>
        <div
          className="sidenav sidenav-ham"
          style={{ borderBottom: "solid 3px white" }}
        >
          <div
            onClick={() => handleSelected(0)}
            className={selected[0] ? "link link-selected" : "link"}
          >
            <Link href="/">
              <a className={selected[0] ? "" : "link-icon--inactive"} href="#">
                <br />
                <br />
                {selected[0] ? (
                  <h4 className="sidebar-text-selected sidebar-text-margin">
                    New
                  </h4>
                ) : (
                  <h4 className="sidebar-text sidebar-text-margin">New</h4>
                )}
              </a>
            </Link>
          </div>

          {/* <hr className="active-hr" /> */}
          <div className="icon-hr" />

          <div
            onClick={() => handleSelected(1)}
            className={selected[1] ? "link link-selected" : "link"}
          >
            <Link href="/BulkPriceChangeLog">
              <a className={selected[1] ? "" : "link-icon--inactive"} href="#">
                <br />
                <br />
                {selected[1] ? (
                  <h4 className="sidebar-text-selected sidebar-text-margin">
                    Price Change Log
                  </h4>
                ) : (
                  <h4 className="sidebar-text sidebar-text-margin">
                    Price Change Log
                  </h4>
                )}
              </a>
            </Link>
          </div>

          {/* <hr className="active-hr" /> */}
          <div className="icon-hr" />

          <div
            onClick={() => handleSelected(2)}
            className={selected[2] ? "link link-selected" : "link"}
          >
            <Link href="/SalesLog">
              <a className={selected[2] ? "" : "link-icon--inactive"} href="#">
                <br />
                <br />
                {selected[2] ? (
                  <h4 className="sidebar-text-selected sidebar-text-margin">
                    Sales Log
                  </h4>
                ) : (
                  <h4 className="sidebar-text sidebar-text-margin">
                    Sales Log
                  </h4>
                )}
              </a>
            </Link>
          </div>

          {/* <hr className="active-hr" /> */}
          <div className="icon-hr" />

          <div
            onClick={() => handleSelected(3)}
            className={selected[3] ? "link link-selected " : "link "}
          >
            <Link href="/StoreFrontDesigns">
              <a className={selected[3] ? "" : "link-icon--inactive"} href="#">
                <br />
                <br />
                {selected[3] ? (
                  <h4 className="sidebar-text-selected sidebar-text-margin">
                    Sales Clock Settings
                  </h4>
                ) : (
                  <h4 className="sidebar-text sidebar-text-margin">
                    Sales Clock Settings
                  </h4>
                )}
              </a>
            </Link>
          </div>

          <div
            onClick={() => handleSelected(4)}
            className={selected[4] ? "link link-selected" : "link"}
          >
            <Link href="/Academy">
              <a className={selected[4] ? "" : "link-icon--inactive"} href="#">
                <br />
                <br />
                {selected[4] ? (
                  <h4 className="sidebar-text-selected sidebar-text-margin">
                    Academy
                  </h4>
                ) : (
                  <h4 className="sidebar-text sidebar-text-margin">Academy</h4>
                )}
              </a>
            </Link>
          </div>

          <div
            onClick={() => handleSelected(5)}
            className={selected[5] ? "link link-selected" : "link"}
          >
            <Link href="/Subscription">
              <a className={selected[5] ? "" : "link-icon--inactive"} href="#">
                <br />
                <br />
                {selected[5] ? (
                  <h4 className="sidebar-text-selected sidebar-text-margin">
                    Subscription
                  </h4>
                ) : (
                  <h4 className="sidebar-text sidebar-text-margin">
                    Subscription
                  </h4>
                )}
              </a>
            </Link>
          </div>
        </div>
      </Collapsible>
    </>
  );
};

export default HamburgerMenu;
