export function browserDetect() {
  let userAgent = window?.navigator?.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "edge";
  } else {
    browserName = "No browser detection";
  }

  return browserName;
}
export const handleModals = (className) => {
  //sub-modal
  let found = document.getElementsByClassName(className);
  found = Array.from(found);
  found.forEach((modalContainer) => {
    // Polaris-Modal-Dialog__Container
    modalContainer.style["-moz-transform"] = "scale(0.75);";
  });
};
export const handleMozilla = () => {
  let browser_ = browserDetect();
  let handleClasses = ["sub-modal", "Polaris-Modal-Dialog__Container"];
  if (browser_ == "firefox") {
    handleClasses.forEach((className) => {
      handleModals(className);
    });
  }
};
// module.exports = { browserDetect, handleMozilla };
