import { browserDetect, handleMozilla } from "../componentUtils/common";
import Sidebar from "./Sidebar";
import { useState, useLayoutEffect, useEffect } from "react";
function Parent(props) {
  const [showChild, setShowChild] = useState(false);

  // Wait until after client-side hydration to show
  useEffect(() => {
    setShowChild(true);
  }, []);

  if (!showChild) {
    // You can show some kind of placeholder UI here
    return null;
  }

  return <Wrapper {...props} />;
}

const Wrapper = (props) => {
  const [browser, setBrowser] = useState();

  useLayoutEffect(() => {
    let browser_ = browserDetect();
    setBrowser(browser_);
    // handleMozilla();
  }, []);

  return (
    <div
      className={
        browser != "firefox" ? "background-div" : "background-div mozilla-width"
      }
    >
      <Sidebar tab={props.tab}></Sidebar>
      {props.children}
    </div>
  );
};

export default Parent;
